import {Utility} from '../Utility';
import {Str} from '../Support/Str';
import {ExtendableProxy} from '../Support/ExtendableProxy';

export class AbstractSelector extends ExtendableProxy
{
    /**
     * Retrieve all Camino.js patterns
     * that can be used when making a query selector
     */
    getSelectorPatterns()
    {
        return [':all'];
    }

    /**
     * Get the pattern from a provided selector when available
     */
    getSelectorByPatternMatch(selector)
    {
        let pattern = selector.split(':');
            selector = pattern.shift();
            pattern = pattern[0];

        // remove the pattern from given selector
        this.givenSelector = selector;

        switch(pattern) {
            case 'all':
                return document.querySelectorAll(selector);
        }
    }

    /**
     * Try retrieve one or more HTML Elements
     * based on their tag type, class, id, pseudo selector,
     * or directly the dom when 'dom' or 'document' is provided
     */
    __querySelectorByType()
    {
        // Selecting an element that is already an instance of HTMLElement
        if( this.selector instanceof HTMLElement ) {
            return this.selector;

        // Selecting document with 'dom' or 'document' keyword
        } else if( this.selector === 'dom' || this.selector === 'document' ) {

            this.selector = document; // Selecting DOM directly
            return this.selector;

        // Selecting window with 'window' or 'w' keyword
        } else if( this.selector === 'window' || this.selector === 'w' ){
            this.selector = window;
            return this.selector;

        // Creating an element with createElement based on element type.
        // For example, creating a div element can be done by passing '<div>'
        } else if( Str.contains(this.selector, '<') ) {
            let elType = this.selector.split('<')[1].replace('>', '');
            this.selector = document.createElement(elType);
            return this.selector;
            
        // Selecting elements in bulk using querySelectorAll
        } else if( Str.contains(this.selector, this.patterns) ) {

            this.selector = this.getSelectorByPatternMatch(this.selector);
            this.isArrayQuery = true;
            return this.selector;

        // Selecting a specific element based on its ID
        } else if( this.selector.indexOf('#') !== -1 ) {
            
            this.selector = Str.replaceWith('#', '', this.selector);
            this.selector = document.getElementById(this.selector);
            
            return this.selector;

        // Selecting a specific element based on its Class
        } else if( this.selector.indexOf('.' !== -1) ) {
            
            this.selector = document.querySelector(this.selector);
            return this.selector;

        // Selecting an element by tag name
        } else {
            this.selector = document.getElementByTagName(this.selector);
            return this.selector;
        }
    }

    /**
     * A checker method that prints errors in case
     * the specified selector does not exist.
     */
    __selectorExists(action)
    {
        if( ! this.selected ) {
            this.printErrorException('error', action + '(): The specified element "' + this.givenSelector + '" does not exist.');
            return false;
        }

        return true;
    }

    /**
     * Check if a parameter has an empty or undefined value
     */
    __paramHasValue(param)
    {
        if( param === '' || param === undefined) {
            return false;
        }

        return true;
    }

    /**
     * Processing multiple elements when (:all) is specified with given selector
     * requires also to iterate over each element and map it to the given closure.
     */
    bulkWithClosure(callback)
    {
        let arr = Array.from(this.get());
        for (var i = 0; i < arr.length; i++) {
            callback(arr[i]);
        }

        return this;
    }

    /**
     * Forward the given method or property,
     * including the available properties directly to Vanilla JavaScript.
     */
    __forwardToVanilla(...args)
    {
        let methodOrProperty = args[0][0],
            currentSelector = args[0][1];

            this.selector = currentSelector;
            this.__querySelectorByType();

        let QS = this.selector;
        args.shift();  // remove methodOrProperty and the selector

        try {
            // Forwarding to Native JavaScript methods with available argumetns
            return QS[methodOrProperty](...args);

        } catch(err) {

            // If there are still args after shifting the property and selector
            // means we are going to handle a native javascript property setter.
            if( args.length > 0 ) {
                let setterProperty = QS[methodOrProperty] ? QS[methodOrProperty] = args[0].toString() : null;
                return setterProperty ? setterProperty :
                    this.exceptionInvalidProperty(methodOrProperty);
            } else {
                // Otherwise this is a getter and we'll ignore any args.
                let getterProperty = QS[methodOrProperty];
                return getterProperty ? getterProperty :
                    this.exceptionInvalidProperty(methodOrProperty);
            }
        }
    }

    /**
     * Throw a console error in case the given value is
     * not a valdi JavaScript property.
     */
    exceptionInvalidProperty(property)
    {
        this.printErrorException('error', 'Invalid Property: " ' + property + ' " is not a valid JavaScript property.');
    }

    /**
     * Make use of the Lemonade Utilities
     * 
     * @return {Object} LemonadeUtils
     */
    utils() {
        return new Utility();
    }

    /**
     * Set value as a string representing the
     * last called method that can be also a getter.
     */
    setValueToString(value)
    {
        this.toString = function() {
            return value;
        }
    }

    /**
     * Prints console errors, warning or messages
     * whenever something goes wrong
     */
    printErrorException(type, message)
    {
    }

    getConsoleLogStyle()
    {
        return 'background-color:white;'
    }
}