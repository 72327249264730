import {Selector} from '../Selectors/Selector';
import {Collection} from '../Support/Collection';

export class AbstractCalendar
{

    settings(custom)
    {

        this.settings = new Collection();

        if( custom ) {
            // Enabling the custom listeners when enabled and also provided
            if( (custom.enableListeners  && custom.listeners) && custom.enableListeners === true ) {
                this.setListeners(custom.listeners);
                this.enableListeners(true);
            } else {
                this.enableListeners(false);
            }

            // Disabling past days based on custom settings
            if( custom.disablePastDays && custom.disablePastDays === true ) {
                this.settings.add('disablePastDays', true);
            }

            // Disabling current day based on custom settings
            if( custom.disableCurrentDay ) {
                this.settings.add('disableCurrentDay', custom.disableCurrentDay)
            }

            // Disable past years when specified
            if( custom.disablePastYears ) {
                this.settings.add('disablePastYears', custom.disablePastYears)
            }

            // Enabling Bookable calendars when specified
            if( custom.enableBookable ) {
                // Transform calendar to bookable per days/nights
                this.settings.add('enableBookable', custom.enableBookable)
                // When specified, it will go deeper and make it hourly bookable
                // instead of day/night bookable
                this.settings.add('enableBookableHours', custom.enableBookableHours ? custom.enableBookableHours : false )
            }

            // Get Popup Container settings if available
            if( custom.popupContainer ) {
                this.settings.add('popupContainer', custom.popupContainer)
            }

            // Adding a range with maximum selectable days after current day
            if( custom.maxDays ) this.settings.add('maxDays', custom.maxDays);

            // Adding custom navigation SVG icons
            if( custom.navigation.next && custom.navigation.prev ) {
                this.settings.add('navigation', custom.navigation);
            }

            // Adding Calendar format based on settings,
            // when available. If not, it will use defaults
            this.settings.add('formats', custom.formats ? custom.formats : {
                month: 'long',
                week: 'short'
            });
        }
    }

    /**
     * An instantiated Camino.js Selector with a given selector name
     * 
     * @param {String} selector
     * 
     * @return {Object}
     */
    el(selector) {
        return new Selector(selector);
    }

    /**
     * Determine if the current instance has any custom listeners provided
     * 
     * @return {Void}
     */
    hasCustomListeners() {
        return this.settings.get('enableListeners');
    }

    /**
     * Determine if the current instance has a named listener provided.
     * 
     * @param {String} key
     * 
     * @return {Void}
     */
    hasListener(key) {
        return (typeof this.settings.get('listeners')[key] === 'function');
    }

    /**
     * Enable or Disable custom event listeners
     * 
     * @param {Boolean} status
     * 
     * @return {Void}
     */
    enableListeners(status) {
        this.settings.add('enableListeners', status);
    }

    /**
     * Set custom listeners passed on instantiating
     * @return {Void}
     */
    setListeners(listeners) {
        this.settings.add('listeners', listeners);
    }

    /**
     * Get a specific value or group of values from given settings
     * 
     * @param  {String} key
     * 
     * @return {String\Array}
     */
    getSettingsValue(key) {
        return this.settings.get(key)
    }

    /**
     * Determine if a specific key exists.
     * 
     * @param  {String}  key
     * 
     * @return {Boolean}     [description]
     */
    hasSettingsKey(key) {
        // return this.options[key] !== undefined ? true : false;
        return key.split('.').reduce((a, b) => a[b], this.options);
    }

    /**
     * Determine if the calendar should be bookable.
     * 
     * @return {Boolean}
     */
    isBookable() {
        let key = 'enableBookable';
        return this.settings.has(key) && this.settings.get(key) === true;
    }

    /**
     * Determine if the calendar should be hourly bookable.
     * 
     * @return {Boolean}
     */
    isHourlyBookable() {
        let key = 'enableBookableHours';
        return this.settings.has(key) && this.settings.get(key) === true;
    }

    /**
     * Return a new Date instance based on given date time
     * 
     * @param  {String} dateTime
     * 
     * @return {Object} Date
     */
    newDateInstance(dateTime) {
        return dateTime ? new Date(dateTime) : new Date();
    }

    /**
     * The date with all months. Todo: Support for i18n.
     * 
     * @return {Array}
     * 
     * @param {Integer} monthIndex
     */
    monthsAsString(index) {
        let format = this.settings.get('formats.month', 'long');
        return this.getMonths(format)[index]
    }

    /*
     * Create a full year with all named months
     * 
     * @param  {String}        It can be either 'short' or 'long'
     * 
     * @return {Array|String}  The month, or an array of months
     */
    getMonths(format) {

        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (mon) {
            return new Date('', mon).toLocaleString({}, {month: format});
        });
    }

    /*
     * Create weekdays based on their order
     * 
     * @param  {String}        It can be either 'short' or 'long'
     * 
     * @return {Array|String}  The month, or an array of months
     */
    getWeekDays(format = 'short') {
        return [1,2,3,4,5,6,0].map(function (weekd) {
            return new Date('2021', 1, weekd).toLocaleString({}, {weekday: format})
        });
    }

    /**
     * Clearing the dates of the previous month.
     * 
     * @return {Void}
     */
    clearCalendar() {
        this.month.innerHTML('');
    }

    /**
     * Remove current active CSS classes
     * 
     * @return {Void}
     */
    removeActiveClass() {
        for (var i = 0; i < this.activeDates.length; i++) {
            this.activeDates[i].classList.remove('vcal-date--selected')
        }
    }

    removeRangeSelection()
    {
        for (var i = 0; i < this.activeDates.length; i++) {
            this.activeDates[i].removeAttribute('date-start-range')
            this.activeDates[i].removeAttribute('date-end-range')
        }
    }

    /**
     * Selecting a specific number of days, before or after date selection.
     * 
     * @param  {String}   type            Internal use, it can be 'before' or 'after'
     * @param  {String}   dataset         Current date based on selection
     * @param  {Integer}  offset          Number of days as offset
     * @param  {Object}   ops             Options array with functions
     * 
     * @return {Object}   Date
     */
    dayBeforeAndAfter(type, dataset, offset, ops) {

        let [basemonth, baseday, year] = this.newDateInstance(dataset.calendarDate)
                                             .toLocaleDateString('en-US').split('/');
        let currentDayNo = 1;

        // Months are 0-indexed, so january starts as 0
        basemonth = (parseInt(basemonth) === 1 ? parseInt(0) : parseInt(basemonth));
        // Convert possible strings to Integer
        baseday = parseInt(baseday);
        // Convert possible negative offsets to positive
        offset = this.toPositive(parseInt(offset));

        if( type == 'before') {
            

            if( baseday < offset ) {
                let prevMonth = '', dayPrevMonth = '';
                prevMonth = basemonth === 0 ? 0 : basemonth - 2;
                dayPrevMonth = ops.getRestDaysOfMonth(prevMonth, this.toPositive(offset - baseday));

                return new Date(year, prevMonth, dayPrevMonth);

            } else {
                let month = '', day = '';
                month = basemonth === 0 ? 0 : basemonth - 1;
                day = this.toPositive(offset - baseday);
                day = day === 0 ? 0 : day;

                return new Date(year, month, day);
            }


        } else if( type == 'after' ) {
            
            let restOfDays = '', day = '', month = '';

            // Calculate the rest of days 
            restOfDays = ops.getRestDaysOfMonth(basemonth, baseday);
            
            if( offset > restOfDays ) {
                let nextMonth = '', nextDayMonth = '';
                
                nextMonth = basemonth === 11 ? 0 : basemonth
                nextMonth = nextMonth === 0 ? 1 : nextMonth

                return new Date(year, nextMonth, offset - restOfDays);

            } else {
                
                let currentMonth = '';
                currentMonth = basemonth === 11 ? 0 : basemonth;
                currentMonth = currentMonth === 0 ? 0 : currentMonth - 1;
                return new Date(year, currentMonth, (baseday + offset) );
            }

        }

    }

    /**
     * Disable past years
     * 
     * @param  {Integer} screenYear
     * @param  {Integer} currentYear
     * 
     * @return {Boolean}
     */
    mustDisablePastYears(screenYear, currentYear) {
        return currentYear > screenYear;
    }

    /**
     * Disable a Navigation button
     * @param  {DOMElement} target
     * 
     * @return {Void}
     */
    disableNavigationBtn(target) {
        this.el(target.currentTarget).style('cursor: not-allowed; opacity:.2');
    }

    /**
     * Enable a Navigation button
     * 
     * @param  {DOMElement} target
     * 
     * @return {Void}
     */
    enableNavigationBtn(target) {
        this.el(target.currentTarget).style('cursor: inherit; opacity: inherit');
    }

    /**
     * Convert negative numbers to positive
     *
     * @param {Integer} number
     */
    toPositive(number) {
        return Math.abs(number);
    }
}