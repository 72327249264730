export class ExtendableProxy {

    constructor() {

        return new Proxy(this, {
        
            /**
             * Set the already registered methods.
             * 
             * @param  {Selector}   instance
             * @param  {String}     methodOrProperty
             * @param  {Mixed}     value
             * 
             * @return {Boolean}
             */
            set: (instance, methodOrProperty, value) => {
                instance[ methodOrProperty ] = value;
                return true;
            },
            
            /**
             * Acting like __call method in PHP, get method filters
             * all calls of non/existing methods and properties.
             * 
             * @param  {Selector}   instance
             * @param  {String}     methodOrProperty
             * @param  {Proxy}      receiver
             * 
             */
            get: (instance, methodOrProperty, receiver) => {
                // some refrences
                // https://stackoverflow.com/questions/37714787/can-i-extend-proxy-with-an-es2015-class
                // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy

                let callable = instance[methodOrProperty];
                
                if( instance && callable === undefined ) {
                    // console.log(methodOrProperty);
                    let forward = function() {
                        [].unshift.call(arguments, [methodOrProperty, instance.givenSelector]);
                        return instance[ '__forwardToVanilla' ].apply( this, arguments );
                    }

                    return forward;
                }
                
                return callable;
            }
        });
    }
}
