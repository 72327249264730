import {AbstractCalendar} from './AbstractCalendar';
import {Listeners} from './CalendarListeners';
import {ConsoleException} from '../Exception/ConsoleException';
import {Helper} from '../Support/Helper';

export class Calendar extends AbstractCalendar
{
    constructor(options) {
        
        super();
        
        this.options = {};
        this.settings(options);

        this.month = this.el('[data-calendar-area="month"]');
        this.renderHeading();

        this.activeDates = null;
        this.date = new Date();
        this.todaysDate = new Date();
        this.availableDay = 0;


        this.date.setDate(1)
        
        this.createMonth()
        this.createListeners()
    }

    /**
     * Rendering the heading of the calendar with the short name of the days
     */
    renderHeading() {
        let head, next, previous, weekString = this.getWeekDays();
        this.headingElement = this.el('<div>').addClass('vcal-week');

        for (var i = 0; i < weekString.length; i++) {
            let day = this.el('<span>').html(weekString[i]);
                this.headingElement.get().appendChild(day.get());
        }

        let parentNode = this.month.get().parentNode;

        // Create Next button
        this.next = this.el('<button>')
                        .addClass('vcal-btn')
                        .attr('data-calendar-toggle', 'next')
                        .html(this.getNavigationIcon('next'))
                        .get();

        // Create Previous button
        this.prev = this.el('<button>')
                            .addClass('vcal-btn')
                            .attr('data-calendar-toggle', 'previous')
                            .html(this.getNavigationIcon('prev'))
                            .get();
        
        // Creathe month label
        this.label = this.el('<div>')
                         .addClass('vcal-header__label')
                         .attr('data-calendar-label', 'month');
        
        // Create the header wrapper and append to it
        // the navigation buttons and month label
        head = this.el('<div>').addClass('vcal-header')
        head.get().appendChild(this.prev)
        head.get().appendChild(this.label.get())
        head.get().appendChild(this.next)
        
        // Inserting navigation heading
        parentNode.insertBefore(head.get(), this.month.get())

        // Inserting heading with the days
        parentNode.insertBefore(this.headingElement.get(), this.month.get())
    }

    /**
     * Retrieve a SVG icon used for creating the prev & next navigation.
     * 
     * @param  {String} type
     * @return {String}
     */
    getNavigationIcon(type) {
        return this.settings.get('navigation')[type];
    }

    /**
     * Click Event Listeners for creating prev & next navigation.
     * 
     * @return {Void}
     */
    createListeners() {
        let _this = this

        this.next.addEventListener('click', function () {
            
            // Clear previously calendar dates
            // Reset the available days. Mainly used for bookable calendars
            _this.clearCalendar()
            this.availableDay = 0;

            // Get the next month and set as for current screen
            _this.date.setMonth( _this.date.getMonth() + 1 )
            _this.createMonth()

        })
    
        // Clears the calendar and shows the previous month
        this.prev.addEventListener('click', function (event) {
            
            // When disablePastYears is enabled it will prevent navigation or rendering past years.
            if( _this.settings.has('disablePastYears') && _this.settings.get('disablePastYears') === true ) {

                if( _this.mustDisablePastYears(_this.date.getFullYear(), _this.todaysDate.getFullYear() ) ) {
                    _this.disableNavigationBtn(event);
                    return false;
                }
            }

            let prevMonthNo = _this.date.getMonth() === 0 ? 11 : _this.date.getMonth();
                prevMonthNo = prevMonthNo - 1;

            if( _this.todaysDate.getMonth() > prevMonthNo) {
                _this.disableNavigationBtn(event);
                return false;
            }

            // Clear previously calendar dates
            // Reset the available days. Mainly used for bookable calendars
            _this.clearCalendar()
            this.availableDay = 0;

            // Get the previous month and set as for current screen
            _this.date.setMonth( _this.date.getMonth() - 1 )
            _this.createMonth()

        })
    }

    /**
     * Creating Day
     * 
     * @param  {Integer} num  [description]
     * @param  {Integer} day  [description]
     * @param  {Integer} year [description]
     */
    createDay(num, day, year, dayCounter) {
        let newDay = this.el('<div>'),
            dateEl = this.el('<span>');

        dateEl.html(num)
        newDay.addClass('vcal-date')
        newDay.attr('data-calendar-date', this.date)
        
        // Spacing the first day in a month will order the entire dates over the current month.
        // Since we're using the native CSS grid system will use grid-column-end to push the first day
        if (num === 1) {
            if (day === 0) {
                newDay.style('grid-column-end: 6')
            } else {
                newDay.style('grid-column-end:' + (day + 1 ) );
            }
        }

        // Disable all the past years.
        // When 'disablePastYears' is provided it will prevent rendering
        // past years except the previous one - just for show.
        if( this.todaysDate.getFullYear() > this.date.getFullYear() ) {
            this.makeDayUnavailable(newDay);

        // Make future years as available.
        // When 'disableYearsMoreThan' has a value (integer),
        // it will disable next years based on provided value
        } else if( this.todaysDate.getFullYear() < this.date.getFullYear() ) {
            this.makeDayAvailable(newDay);

        // In case the calendar is in the same month with the current month 
        } else if( this.todaysDate.getMonth() === this.date.getMonth()  ) {

            // Disabling past days of the current month
            if (this.settings.get('disablePastDays')) {

                if( this.date.getDate() <= this.todaysDate.getDate() - 1 ) {
                    this.makeDayUnavailable(newDay);
                } else {
                    this.makeDayAvailable(newDay);
                }

            }

        // Making next months available
        } else {
            
            // count the current day as being available
            this.availableDay++;

            // Keep enabled only the numer of days specified starting with the current day
            if( this.options.maxDays > 0 ) {
                if( this.options.maxDays >= this.availableDay  ) {
                    this.makeDayAvailable(newDay);
                } else {
                    this.makeDayUnavailable(newDay);
                }
            } else {
                this.makeDayAvailable(newDay);
            }


        }

        if (this.date.toString() === this.todaysDate.toString()) {
            newDay.addClass('vcal-date--today')
        }

        newDay.get().appendChild(dateEl.get())
        this.month.appendChild(newDay.get())
    }

    /**
     * Set the attribute and class of an available day.
     * 
     * @param  {Object} day         Camino.js Selector
     * 
     * @return {Void}
     */
    makeDayAvailable(day) {
        day.addClass('vcal-date--active')
        day.attr('data-calendar-status', 'active')  
    }

    /**
     * Set the attribute and class of an unavailable day.
     * 
     * @param  {Object} day         Camino.js Selector
     * 
     * @return {Void}
     */
    makeDayUnavailable(day) {
        day.addClass('vcal-date--disabled')
    }

    /**
     * Event Listener on Clicking on available date
     */
    dateClicked() {

        let _this = this,
            popupContainer;
        this.activeDates = document.querySelectorAll('[data-calendar-status="active"]')
        
        for (var i = 0; i < this.activeDates.length; i++) {

            this.activeDates[i].addEventListener('click', function(event) {

                _this.removeActiveClass()
                this.classList.add('vcal-date--selected')

                // When custom listeners are enabled and the onDaySelected() is provided
                // it will trigger the event and resolve the closure dependencies.
                if( _this.hasCustomListeners() && _this.hasListener('onDaySelected') ) {

                    let activeDataset = this.dataset,
                        ops = Listeners.closures(_this, activeDataset);

                    // Trigger the onDaySelected() event and pass the current date,
                    // and as a secondary parameter pass a formatter so the date format can be 
                    // changed based on custom settings
                    _this.settings.get('listeners').onDaySelected(ops);
                }
            })
        }
    }

    /**
     * Event listener for enabling date range calendars.
     * 
     * @return {Void}
     */
    dateRangeClicked() {

        this.activeDates = document.querySelectorAll('[data-calendar-status="active"]')

        let _this = this,
            startDate,
            startDateEl,
            startDateNumber,
            startPos,
            endDate,
            endDateEl,
            endDateNumber,
            endPos,
            countDay = 0,
            popupContainer;

        for (var i = 0; i < this.activeDates.length; i++) {
            countDay++
            this.activeDates[i].addEventListener('click', function(event) {
                
                // Creating the start date selection
                if( startDate === undefined && endDate === undefined ) {
            
                    startDate = this.dataset
                    startDateEl = event.currentTarget
                    startDateNumber = _this.newDateInstance(startDate.calendarDate);
                    startPos = startDateNumber.getDate();
                    
                    _this.selectRangeDay('start', this);

                // Creating the end date selection
                } else if(startDate !== undefined && endDate === undefined) {

                    endDate = this.dataset
                    endDateEl = event.currentTarget
                    endDateNumber = _this.newDateInstance(endDate.calendarDate)
                    endPos = endDateNumber.getDate();

                    // Swap dates in case the end date is bigger than start date.
                    if( endDateNumber.getTime() < startDateNumber.getTime()  ) {
                        
                        let _startDate = startDate,
                            _startDateEl = startDateEl,
                            _startDateNumber = startDateNumber,
                            _startPos = startPos;

                        startDate = endDate
                        startDateEl = endDateEl
                        startDateNumber = endDateNumber
                        startPos = endPos

                        endDate = _startDate
                        endDateEl = _startDateEl
                        endDateNumber = _startDateNumber;
                        endPos = _startPos;

                        _this.selectRangeDay('start', this);

                    } else {
                        _this.selectRangeDay('end', this);
                    }

                    // Create the range selection based on selected start date & end date
                    let daysBetween = _this.createRangeSelection(startDate.calendarDate, endDate.calendarDate);

                    // Select all days inside current range selection
                    for (var i = 0; i < daysBetween; i++) {
                        let dayBetween = _this.activeDates[startPos + i];
                        _this.selectRangeDay('between', dayBetween);
                    }

                    if( _this.settings.has('popupContainer') ) {
                        popupContainer = _this.createPopup(this);
                    }

                    _this.settings.get('listeners').onRangeSelection(
                            startDateNumber,
                            endDateNumber,
                            daysBetween + 2,
                            daysBetween,
                            Listeners.closures(_this, endDate)
                        )

                // Resetting the current range selection
                } else {

                    // When Popup Container is revealed will need to prevent
                    // closing and clearing the current selection range on clicking
                    // the area.
                    if( Helper.isChildOf(event.target, '.vcal-date--popup-container') ) {

                    // Otherwise, it will clear the current selection,
                    // active classes, remove the popup container
                    // and trigger the onRangeDeselection() event
                    } else {
                        startDate = undefined; endDate = undefined
                        _this.removeRangeSelection(); _this.removeActiveClass();
                        _this.settings.get('listeners').onRangeDeselection();

                        if( _this.settings.has('popupContainer') ) {
                            popupContainer.remove();
                        }
                    }
                }

            })
            
        }
    }

    selectRangeDay(type, day) {
        day.classList.add('vcal-date--selected')
        day.classList.add('vcal-date--' + type)
        day.setAttribute('date-'+type+'-range', day.getAttribute('data-calendar-date'))
    }

    /**
     * Create a popup with custom content.
     */
    createPopup(endDayElement) {
        let div = this.el('<div>')
                        .html(this.settings.get('popupContainer.content'))
                        .addClass('position-absolute')
                        .addClass('vcal-date--popup-container')
                        .attr('data-calendar-popup', true)

        this.el(endDayElement)
            .addClass('position-relative')
            .appendChild(div.get());

        // Checking if the opened popup is fully visibile in viewport
        let viewCorrection = Helper.toViewport(div.getPosition());
        if( viewCorrection ) {
            for (var i = 0; i < viewCorrection.length; i++) {
                if( viewCorrection[i] == 'right' ) {
                    div.addStyle('right', '15px')
                    div.addStyle('left', 'inherit')
                } else if( viewCorrection[i] == 'bottom' ) {
                    div.addStyle('bottom', '60px')
                    div.addStyle('top', 'inherit')
                }
            }
        }
        return div;
    }

    /**
     * Creating the range selection based on selected start & end date
     * 
     * @param  {String} selectedStartDate
     * @param  {String} selectedEndDate
     * 
     * @return {Void}
     */
    createRangeSelection(selectedStartDate, selectedEndDate) {

        let startDateInstance  = this.newDateInstance(selectedStartDate),
            endDateInstance = this.newDateInstance(selectedEndDate),
            daysBetween = endDateInstance.getTime() - startDateInstance.getTime();
            
        daysBetween = Math.ceil(daysBetween / (1000 * 3600 * 24));
        daysBetween = daysBetween - 1; // exclude the end date which is already selected

        return daysBetween;
    }

    /**
     * Create current month.
     */
    createMonth() {
        let currentMonth = this.date.getMonth(),
            dayCounter = 1;

        while (this.date.getMonth() === currentMonth) {
            this.createDay(
                this.date.getDate(),
                this.date.getDay(),
                this.date.getFullYear(),
                dayCounter
            )
            dayCounter++;
            this.date.setDate(this.date.getDate() + 1)
        }

        // While loop trips over and day is at 30/31, bring it back
        this.date.setDate(1)
        this.date.setMonth(this.date.getMonth() - 1)
        
        // Update the month label to the current one
        let currentDateLabel = this.monthsAsString(this.date.getMonth()) + ' ' + this.date.getFullYear();
        this.label.html(currentDateLabel)
        
        // Update dateClicked event according to the current month
        if( this.isBookable() && this.isHourlyBookable() === false ) {
            this.dateRangeClicked()
        } else if( this.isBookable() && this.isHourlyBookable() ) {
            // this.hourlyRangeClickable();
        } else {
            this.dateClicked()
        }

        // When custom listeners are enabled and onMonthChanged listener is provided
        // it will trigger the event and pass the current dates
        if( this.hasCustomListeners() ) {
            if( this.hasListener('onMonthChanged') ) {
                this.settings.get('listeners').onMonthChanged(currentDateLabel, this.date)
            }
        }
    }
}