import {AbstractSelector} from './AbstractSelector';
import {ExtendableClass} from '../Support/ExtendableClass';
import {HtmlAttributes} from './HtmlAttributes';
import {Element} from './Element';
import {EventListener} from './Listener';
/**
 * A minimal method that instantiate the Selector class.
 * 
 * @param  {String} || {Array} selector
 * @return {Object} Selector
 */
export class Selector extends
    ExtendableClass(
        AbstractSelector,
        HtmlAttributes,
        EventListener,
        Element
    ) {

    constructor(selector) {
        super();
        this.patterns = this.getSelectorPatterns();
        this.givenSelector = selector; 
        this.selector = selector;
        this.hasSelectedElement = false;
        this.isArrayQuery = false;
        this.selected = this.__querySelectorByType();
        return this;
    }

    get() {
        if( this.__selectorExists() ) {
            return this.selected;
        }
    }

}
