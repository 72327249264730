export const Listeners = {

    closures: (_this, activeDataset) => {

        // Formatting date based on selected day using Date Class
        // @see  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
        function getFormatted(specificDate, options, locale = 'en-US') {
            return _this.newDateInstance(specificDate ? specificDate : activeDataset.calendarDate)
                        .toLocaleDateString(locale, options)
        }

        // Get a day after specific number of days starting from selection.
        function getDayAfter(offset = 0) {
            return _this.dayBeforeAndAfter('after', activeDataset, offset, this);
        }

        // Get a day before specific number of days starting from selection.
        function getDayBefore(offset = 0) {
            return _this.dayBeforeAndAfter('before', activeDataset, offset, this);
        }

        // Get tomorrow starting with the selection
        function getTomorrow() {
            return this.getDaysAfter(1);
        }

        // Get today date on each selection.
        function getToday() {
            return this.getTodayInstance();
        }

        // Get today with Date instance
        function getTodayInstance(){
            return _this.newDateInstance();
        }

        // Retrieve the last day of a specific month based by month number
        function getLastDayOfMonth(monthNumber) {
            return new Date(
                        this.getTodayInstance().getFullYear(),
                        parseInt(monthNumber), 0);
        }

        // Retrieve the rest days of the month based on selected day
        function getRestDaysOfMonth(month, day) {
            return this.getLastDayOfMonth(parseInt(month)).getDate() - parseInt(day);
        }

        // It simply returns a Date class instance based on selection
        // so you can be able to use all the available Date methods and properties
        // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
        function getDate() {
            return _this.newDateInstance(activeDataset.calendarDate);
        }

        return ({
            'getFormatted': getFormatted,
            'getDayAfter': getDayAfter,
            'getDayBefore': getDayBefore,
            'getTomorrow': getTomorrow,
            'getToday': getToday,
            'getTodayInstance': getTodayInstance,
            'getLastDayOfMonth': getLastDayOfMonth,
            'getRestDaysOfMonth': getRestDaysOfMonth,
            'getDate': getDate
        })
    }
};