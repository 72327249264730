/**
 * A lightweight Collection handler that collects Array with objects
 * @see  reference https://github.com/ecrmnn/collect.js
 */
export class Collection
{
    constructor(collection)
    {
        this.collection = collection ? [collection] : {};
    }

    /**
     * Determine if a specific key exists.
     * 
     * @param  {String}  key        A single key or multiple using dot annotation
     * 
     * @return {Boolean}
     */
    has(key) {
        return ( this.get(key) !== undefined);
    }

    /**
     * Retrieve a value based on a specific key.
     * 
     * @param  {String}  key        A single key or multiple using dot annotation
     * 
     * @return {Boolean}
     */
    get(key, defaultValue) {
        let data = key.split('.').reduce((a, b) => a[b], this.collection);   
        return data ? data : defaultValue;
    }

    /**
     * Add one or more keys to the collection.
     *
     * @param  {String} key         The key identifier of the value
     * @param  {Mixed} value        The mixed value
     * 
     * @return {Object} Collection
     */
    add(key, value) {
        this.collection[key] = value;
        return this;
    }

    /**
     * Sort things
     * https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
     */
    sort()
    {
        return this.collection.sort();
    }

    /**
     * Return the collection instance.
     * 
     * @return {Object}
     */
    all() {
        return this.collection;
    }
}