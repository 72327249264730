import {Selector} from './Selectors/Selector';
import {ContentEditable} from './Components/ContentEditable';
import {Calendar} from './Components/Calendar';
import {Utility} from './Utility';
import {Str} from './Support/Str';

/**
 * A powerful element selector that comes with methods in chain,
 * and also forwards properties and methods to native API.
 * 
 * @param  {String} selector        Can be a tag type, ID, class or pseudo selector.
 * @return {Selector} object
 */
export function el(selector)
{
    return new Selector(selector);
}

export function str()
{
    return Str;
}

export function contentEditable(settings)
{
    return new ContentEditable(settings);
}

export function calendar(settings)
{
    return new Calendar(settings);
}

export function utils()
{
    return new Utility();
}