export class Utility {

    isArray(val)
    {
        return Array.isArray(val);
    }

    isObject(val)
    {
        return typeof val === 'object';
    }
}