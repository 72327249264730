/**
 * Str Helper in Larave Style
 * @see  https://github.com/zhorton34/laravel-js-str/blob/master/src/index.js
 * @type Object
 */
export const Str = {
    
    /**
     * Determine if a given
     * string contains a given substring.
     *
     * @param haystack
     * @param needles
     *
     * @return boolean
     */
    contains(haystack, needles = [])
    {
        return Array.isArray(needles) ?
                    needles.some(needle => haystack.includes(needle)) :
                    haystack.includes(needles);
    },

    /**
     * Determine if a given string contains all array values
     *
     * @param haystack
     * @param needles[]
     *
     * @return boolean
     */
    containsAll(haystack, needles = [])
    {
        return needles.every(needle => haystack.includes(needle));
    },

    /**
     * Determine if a given string matches a given pattern
     *
     * @param pattern
     * @param value
     *
     * @return boolean
     */
    is(pattern, value)
    {
        let patterns = Array.isArray(pattern) ? pattern : [pattern];

        return patterns.some(pattern => {
            if (value === pattern) return true;
            else if (pattern.includes('*') === false) return (new RegExp(pattern).test(value));
            else if (pattern.includes('*')) return (new RegExp(pattern.replace(/\*/g, '.*'))).test(value);
        });
    },

    /**
     * Limit the number of characters in a string
     *
     * @param value
     * @param limit
     * @param end
     *
     * @return string
     */
    limit(value = '', limit = 100, end = '...')
    {
        return value.length < limit ? value : value.slice(0, limit) + end;
    },

    /**
     * Generate a more truly "random" alpha-numeric string.
     *
     * @param length
     *
     * @return string
     */
    random(length = 16)
    {
        let random = '';
        let alpha_numeric = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";

        for (let i = 0; i < length; i++) {
            let character_index = Math.floor(Math.random() * Math.floor(alpha_numeric.length));

            random += alpha_numeric.slice(character_index, character_index + 1);
        }

        return random;
    },

    /**
     * Replace a given value in the string 
     * 
     * @param search
     * @param replace
     * @param content
     */
    replaceWith(search, replace, content)
    {
        return content.replace(search, replace);
    }
}