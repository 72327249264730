export const ConsoleException = {

    // Print any type of console message
    print(msg, type = 'log') {
        console[type]('%c' + msg, this[type]());
    },

    // Implement a minimal version of this
    // https://www.bennadel.com/blog/3941-styling-console-log-output-formatting-with-css.htm
    echo(callback) {

        let highlights = [];

        return callback({

            warning(value) {

                highlights.push({
                    value: value,
                    css: 'font-weight:bold;'
                })

                // console.log(highlights.join(''));
            }
        })
    },

    // Groups are cool for grouped stuff
    group(msgs, title = 'Stack', style) {
        console.group('%c' + title, this[style])
            msgs.forEach((msg) => { this.print(msg); })
        console.groupEnd();
    },

    log() {
       return 'background-color:white; color:#555; padding:4px 10px; border-radius:25px; font-family:Arial; font-size:12px;'
    }
}