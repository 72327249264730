export class Element {

    /**
     * The Element.getBoundingClientRect() method returns a DOMRect
     * object providing information about the size of an element
     * and its position relative to the viewport.
     * 
     * @return {DOMRect}
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
     */
    getPosition() {
        return this.get().getBoundingClientRect();
    }

    /**
     * Get the innerWidth of an element.
     * 
     * @return {Integer}
     */
    innerWidth() {
        return this.get().innerWidth || this.get().clientWidth;
    }

    /**
     * Get the innerHeight of an element.
     * 
     * @return {Integer}
     */
    innerHeight() {
        return this.get().innerHeight || this.get().clientHeight;
    }

//
// Text / HTML Content Setters and Getters
//
    text(content) {
        if( this.__selectorExists('text') ) {
            this.setValueToString(this.get().textContent);
        }

        if( content === '' || content === undefined ) {
            return this.toString();
        }

        this.get().textContent = content            
        
        return this;
    }

    html(htmlContent) {
        htmlContent ? this.get().innerHTML = htmlContent : this.get().innerHTML();
        return this;
    }

    // Inserts a DOMElement before a specific DOMElement
    showBefore(newElement) {
        this.get().parentNode.insertBefore(newElement, this.get());
        return this;
    }

    // Inserts a DOMElement after a specific DOMElement
    showAfter(newElement) {
        this.get().parentNode.insertBefore(newElement, this.get().nextSibling);
        return this;
    }

//  
//  Text/Font Inline Style Properties related
//  
    addStyle(property, value) {
        this.get().style[property] = value;
        return this;
    }
    
    style(css)
    {
        let inlineString = '';
        
        if( this.utils().isObject(css) ) {
            
            Object.keys(css).forEach(function(property) {
                inlineString += property + ':' + css[property] + ';';
            });

            css = inlineString;
        }

        this.get().style.cssText = css;

        return this;
    }

    color(value) {
        this.addStyle('color', value);
        return this;
    }
    align(value) {
        this.addStyle('textAlign', value);
        return this;
    }
    weight(value) {
        this.addStyle('fontWeight', value);
        return this;
    }
    size(value) {
        this.addStyle('fontSize', value);
        return this;
    }

// 
// Inline Style shortcuts for CSS Element
// 
    bgColor(color) {
        this.addStyle('backgroundColor', color);
        return this;
    }
    hide() {
        this.addStyle('display', 'none');
        return this;
    }
    show() {
        this.addStyle('display', 'block');
        return this;
    }
    opacity(value) {
        this.addStyle('opacity', value);
        return this;
    }
    display(value) {
        this.addStyle('display', value);
        return this;
    }
    border(value) {
        this.addStyle('border', value);
        return this;
    }
//
// Element Alignment Related
//
    float(value) {
        this.addStyle('float', value);
        return this;
    }
// 
// Simple DOM Mutators
// 
    remove() {
        if( this.__selectorExists('remove') ) {
            this.get().remove();
        }
    }
}