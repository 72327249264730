/**
 * 
 */
export class EventListener
{

//
// Shortcut methods to addEventListener()
//
    listener(event, callback, capture = false) {
        this.get().addEventListener(event, callback, capture === undefined ? false : true);
    }

    on(event, callback, capture) {
        return this.listener(event, callback, capture);
    }

// 
// Specific Event Methods
//
    click(callback, capture) {
        return this.listener('click', callback, capture);
    }

    hover(callback, capture) {
        return this.listener('mouseover', callback, capture);
    }

    out(callback, capture) {
        return this.listener('mouseout', callback, capture);
    }

    move(callback, capture) {
        return this.listener('mousemove', callback, capture);
    }

// Window Event Listener related
    mouseup(callback, capture) {
        return this.listener('mouseup', callback, capture);
    }

// 
// Specific Keyboard Events
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent
// 
    keydown(callback, capture  = false) {
        return this.listener('keydown', callback)
    }

    keyup(callback, capture  = false) {
        return this.listener('keyup', callback, capture)
    }

// 
// Specific Input Event
// https://developer.mozilla.org/en-US/docs/Web/API/InputEvent
// 
    // TODO

// 
// Specific Focus Event related
// https://developer.mozilla.org/en-US/docs/Web/API/FocusEvent/relatedTarget
// 
    focus(callback, capture) {
        return this.listener('focus', callback, capture);
    }

    focusIn(callback, capture) {
        return this.listener('focusin', callback, capture);
    }

    focusOut(callback, capture) {
        return this.listener('focusout', callback, capture);
    }

    blur(callback, capture) {
        return this.listener('blur', callback, capture);
    }

// 
// Remove A specific Event Listener
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener
// 
    deleteListener(type, listener, capture){
        return this.get().removeEventListener(type, listener, capture);
    }

}