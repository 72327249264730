import {ConsoleException} from '../Exception/ConsoleException';

/**
 * A collection with Helper methods
 * that keeps you hydrated while coding.
 * 
 * @type {Object}
 */
export const Helper = {

    /**
     * Determine if the given value is an Array.
     * 
     * @return {Boolean}
     */
    isArray(value) {
        return Array.isArray(value);
    },

    /**
     * Determine if given value is an Object.
     *
     * @return {Boolean}
     */
    isObject(value) {
        return typeof value === 'object';
    },

    /**
     * Determine if an object element is a specific type
     * 
     * @param  {DOMObject} value
     * @param  {String} type
     * 
     * @return {Boolean}
     */
    typeOf(object, type) {
        return typeof object === type;
    },

    /**
     * Determine if an element is fully visibile in viewport.
     * 
     * @param  {Object} posObject
     */
    inViewport(posObject) {

        return (
            posObject.top >= 0 &&
            posObject.left >= 0 &&
            posObject.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            posObject.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
    },

    /**
     * Get the position that must be corrected for
     * positioning an element in Viewport
     * 
     * @param  {Object} pos
     * 
     * @return {[type]}           [description]
     */
    toViewport(pos) {
        
        if( ! this.inViewport(pos) ) {
            let reposition = [];
            
            if( (pos.top >= 0) === false ) {
                reposition.push('top')
            }

            if( (pos.left >= 0) === false ) {
                reposition.push('left')
            }

            if( (pos.bottom <=  (window.innerHeight || document.documentElement.clientHeight) ) === false ) {
                reposition.push('bottom')
            }

            if( pos.right <= (window.innerWidth || document.documentElement.clientWidth) === false ) {
                reposition.push('right')
            }

            return reposition;
        }
    },
    
    /**
     * The closest() method traverses the Element and its parents,
     * until it finds a node that matches the provided selector string.
     * 
     * Will return itself or the matching ancestor. If no such element exists, it returns null.
     * 
     * @param  {DOMElement} element
     * @param  {String} selector
     * 
     * @see  https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
     * 
     * @return {DOMElement|Null}
     */
    closest(element, selector) {
        return element.closest(selector);
    },

    /**
     * Return a boolean response in case
     * a DOMElement is a child of specified selector.
     * 
     * @return {Boolean} [description]
     */
    isChildOf(element, selector) {
        return this.closest(element, selector) !== null;
    }
}