export class HtmlAttributes
{
    /**
     * Set or overwrite an HTML attribute
     */
    attr(name, value)
    {
        if( this.__selectorExists('attr') && name !== undefined ) {

            // When value is not defined, we'll transform the attr method in a getter,
            // and set the current value of the provided attribute with setValueToString().
            if( value === undefined ) {
                if( this.isArrayQuery ) {
                    // Adding an attribute without value in bulk for NodeList
                    this.bulkWithClosure((el) => { 
                        this.setValueToString(el.getAttribute(name));
                    });
                } else {
                    // Adding an attribute without value for a single selected element
                    this.setValueToString(this.get().getAttribute(name));
                }

                return this;
            }

            // Otherwise it will set a new attribute with name and provided value
            if( this.isArrayQuery ) {
                // Adding attributes in bulk for a group of NodeList
                this.bulkWithClosure((el) => { 
                    el.setAttribute(name, value);
                });

            } else {
                // Adding an attribute for a single selected element
                this.get().setAttribute(name, value ? value : '');
            }

        } else {
            this.printErrorException('error', 'attr(): A name must be specified when setting or getting an attribute.');
        }

        return this;
    }

    /**
     * Add one or more CSS Classes to the
     * selected element/elements.
     */
    addClass(oneOrMany)
    {
        if( this.isArrayQuery ) {
            return this.bulkWithClosure(oneOrMany, (el) => { 
                el.classList.add(oneOrMany);
            });
        }

        this.get().classList.add(oneOrMany);
        return this;
    }

    /**
     * Remove one to many CSS classes from
     * selected element/elements.
     */
    removeClass(oneOrMany) 
    {
        this.get().classList.remove(oneOrMany);
        return this;
    }
}