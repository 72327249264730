import {Selector} from '../Selectors/Selector';
import {CursorCaretPosition} from './CursorCaretPosition';
import {ConsoleException} from '../Exception/ConsoleException';
import {Utility} from '../Utility';

export class ContentEditable
{
    constructor(settings) {
        this.helper = new Utility();
        this.range = document.createRange();
        this.selection = window.getSelection();
        this.elements = [];
        this.settings = settings;
        this.getEditableAreas(settings.selectors);
    }

    /**
     * Get the provided selectors in order to make it content editable
     * areas and bind all the features of Camino's ContentEditable Component.
     * 
     * @param  {Object} selectors
     * @return
     */
    getEditableAreas(selectors){

        if( this.helper.isObject(selectors) ) {
            // Providing various selectors in order to make it content editable
            // can be done by passing an array with objects containing
            // 'selector' key and optional arguments, like: 'required', 'min', 'max'
            if( this.helper.isArray(selectors) ) {

                selectors.forEach(function(selector) {
                    console.log(selector);
                });

            } else {

            }

            return;
        }

        // If there are no specific selectors provided it will try
        // to make a query selector based on contenteditable attribute
        if( selectors === undefined ) {
            this.elements = new Selector('[contenteditable="true"]:all');
            this.setContentEditableListeners();
            return;
        }

        ConsoleException.group([
            'Camino.js: 👉 ContentEditable component requires at least one selector. It can be either a Class, ID, or a Tag name.',
            'Otherwise remove the `selector` key from settings for auto bind all elements with `contenteditable="true"` attr.'
        ]);

        // ConsoleException.echo((log) => {
        //     log.warning('test');
        // });
    }

    allowTextOnly()
    {
        return this.settings.textOnly && this.settings.textOnly === true ? true : false;
    }

    /**
     * Setup Content Editable Listeners for each element from array with following listeners
     * click, on clipboard paste, and on keyup
     */
    setContentEditableListeners()
    {
        let listeners = ['onClick', 'onBlur', 'onPaste', 'onKeypress', 'onKeyup'];

        this.elements.bulkWithClosure((el) => { 
            listeners.forEach((listener) => {
                this[listener](new Selector(el), new CursorCaretPosition(el));
            });
        });
    }

    /**
     * Adding Event Listener on Click
     */
    onClick(field, caret)
    {
        field.on('click', event => {
            event.preventDefault();
            field.attr('editable--action', 'click');
            field.attr('editable--caret-position', caret.getPosition());
            this.getClosureIfExists('onClick', field, caret.getPosition());
        });
    }

    onBlur(field, caret)
    {
        field.on('blur', event => {
            event.preventDefault();
            field.attr('editable--action', 'blur');
            field.attr('editable--caret-position', '');
        });
    }

    /**
     * Adding Event Listener on Paste
     */
    onPaste(field, caret)
    {
        field.on('paste', event => {
            event.preventDefault();
            field.attr('editable--action', 'paste');
            field.attr('editable--caret-position', caret.getPosition());

            this.setClipboardContent(field.get(), event, caret.getPosition());
            this.getClosureIfExists('onPaste', field, caret.getPosition());
        });
    }

    /**
     * Adding Event Listener on Keypress.
     * This listener is used only for keeping track of the current caret position.
     */
    onKeyup(field, caret)
    {
        field.on('keyup', event => {
            field.attr('editable--caret-position', caret.getPosition());
            this.getClosureIfExists('onKeyup', field, caret.getPosition());
        });
    }

    /**
     * Adding Event Listener on Keypress.
     * This listener is used only for keeping track of the current caret position.
     */
    onKeypress(field, caret)
    {
        field.on('keypress', event => {
            field.attr('editable--caret-position', caret.getPosition());
            this.getClosureIfExists('onKeypress', field, caret.getPosition());
        });
    }

    getClosureIfExists(event, field, caretPosition)
    {
        if (typeof this.settings[event] === 'function') { 
            this.settings[event](field, caretPosition);
        }
    }

    /**
     * Setting the clipboard content by
     * cleaning up data from unwanted html tags
     */
    setClipboardContent(editableField, event, caretPosition)
    {
        let clipboard = event.clipboardData.getData("text/plain");
            clipboard = clipboard.replace(/(<([^>]+)>)/gi, "");

        // In case there is already a content inside the area
        // will need to paste the content based on the current caret position.
        if( editableField.innerHTML ) {
            let currentContent = editableField.innerHTML,
                before = currentContent.substring(0,  caretPosition),
                after  = currentContent.substring(caretPosition, currentContent.length);

            editableField.innerHTML = before + clipboard + after;

        } else {
            editableField.innerHTML = clipboard;
        }

        this.setCaretAtEnd(editableField);
    }

    /**
     * Set the end of the content editable area and moves the
     * input cursor at the end of the content, based on its range and selection.
     */
    setCaretAtEnd(editableField)
    {
        this.range.selectNodeContents(editableField);
        this.range.collapse(false);

        this.selection.removeAllRanges();
        this.selection.addRange(this.range);
    }

    /**
     * Retrieve the current selected text
     */
    currentSelection()
    {
        let html = '';
        
        if (typeof window.getSelection !== 'undefined') {
            let sel = this.selection;
            
            if (sel.rangeCount) {
                let container = document.createElement('div');
                for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                    container.appendChild(sel.getRangeAt(i).cloneContents());
                }
                html = container.innerHTML;
            }
        } else if (typeof document.selection !== 'undefined') {

            if (document.selection.type === 'Text') {
                html = document.selection.createRange().htmlText;
            }
        }

        return html;
    }

}